<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept 
            :isFirstValue="false" 
            type="search" 
            name="deptCd" 
            v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 종류 -->
          <c-select
            type="search"
            codeGroupCd="SOP_PRE_WORK_CHECK_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="sopPreWorkCheckClassCd"
            label="점검구분"
            v-model="searchParam.sopPreWorkCheckClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="일상점검 항목 목록"
      tableId="workCheckItem"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            :showLoading="false" 
            label="LBLADD" 
            icon="add" 
            @btnClicked="addPreWorkCheckItem" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="savePreWorkCheckItem"
            @btnCallback="savePreWorkCheckItemCallback"/>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'managers'">
          <c-tag 
            :editable="editable"
            icon="person_outline"
            itemText="userName"
            itemValue="check"
            name="managers" 
            v-model="props.row.managers"
            @addTag="addManager(props.row)"
            @removeTag="item => removeManager(props.row, item)"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'preWorkCheckItem',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        sopPreWorkCheckClassCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'plantCd',
            field: 'plantCd',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:80px',
            type: 'plant',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:100px',
            type: 'dept',
            deptCd: 'deptCd',
          },
          {
            name: 'sopPreWorkCheckClassCd',
            field: 'sopPreWorkCheckClassCd',
            label: '점검구분',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:130px',
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            codeGroupCd: 'SOP_PRE_WORK_CHECK_CLASS_CD',
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목명',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width:150px',
            type: 'text',
          },
          {
            name: 'checkItemSubName',
            field: 'checkItemSubName',
            label: '점검세부항목명',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width:300px',
            type: 'text',
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'left',
            sortable: true,
            style: 'width:100px',
            type: 'text',
          },
          {
            name: 'unit',
            field: 'unit',
            label: '단위',
            align: 'center',
            sortable: true,
            style: 'width:70px',
            type: 'text',
          },
          {
            name: 'managers',
            field: 'managers',
            label: '담당자',
            align: 'left',
            sortable: true,
            style: 'width:200px',
            type: 'custom',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:50px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:50px',
            sortable: true,
            type: 'number',
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      row: null,
      editable: true,
      listUrl: '',
      listClassUrl: '',
      saveUrl: '',
      isSave: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.pwc.item.list.url
      this.saveUrl = transactionConfig.sop.pwc.item.save.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addPreWorkCheckItem() {
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        sopPreWorkCheckItemId: uid(),  // 작업 전 안전점검항목 일련번호
        plantCd: null,  // 사업장 코드
        deptName: '',  // 부서코드
        deptCd: '',  // 부서코드
        sopPreWorkCheckClassCd: null,  // 작업 전 안전점검 구분 코드
        checkItemName: '',  // 점검항목명
        checkItemSubName: '',  // 점검세부항목명
        remarks: '',  // 점검세부항목명
        useFlag: 'Y',  // 사용여부
        sortOrder: 0,  // 순번
        managers: [],
        deleteManagers: [],
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    addManager(row) {
      this.row = row
      this.popupOptions.title = 'LBL0000560'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.row.managers) this.row.managers = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.row.managers, { userId: item.userId }) === -1) {
            this.row.managers.push({
              sopPreWorkCheckItemManagerId: uid(),  // 작업 전 안전점검항목 담당자 일련번호
              sopPreWorkCheckItemId: this.row.sopPreWorkCheckItemId,  // 작업 전 안전점검항목 일련번호
              userId: item.userId,  // 사용자ID
              userName: item.userName,  // 사용자ID
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          }
        })
        console.log(this.$_.cloneDeep(this.grid.data))
      }
    },
    removeManager(row, item) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '제외하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if (this.$_.findIndex(row.deleteManagers, { userId: item.userId }) === -1 && item.editFlag !== 'C') {
            if (!row.deleteManagers) row.deleteManagers = [];
            row.deleteManagers.push(item)
          }
          row.managers = this.$_.reject(row.managers, item)
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    savePreWorkCheckItem() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    savePreWorkCheckItemCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
